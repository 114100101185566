import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import trashOutlineImage from "../../assets/trash-outline.svg";
import copyOutlineImage from "../../assets/copy-outline.svg";
import IconButton from "../../components/Buttons/IconButton";
import ReferencePoint from "../../components/ReferencePoint/ReferencePoint";
import UnitInput from '../../components/UnitInput/UnitInput';
import Consts from '../../Consts';
import classes from './EditJigItem.module.css';

function EditJigItem(props) {

    const { t } = useTranslation();
    const slot = props.slot;
    const index = props.index;
    const focusedSlotIndex = props.focusedSlotIndex;

    const myStyleLeftCell = { "paddingRight": "0.625rem", "borderRight": "1px solid #CCC" }
    const myStyleRightCell = { "paddingLeft": "0.625rem" }

    return (<tr className='clickable'
        style={props.isActive ? { background: Consts.SELECTION_COLOR } : { background: 'white' }}
        onFocus={() => props.onFocus(index)} onClick={() => props.onFocus(index)} onBlur={() => { index !== focusedSlotIndex && props.onBlur() }}>
        <td><div className={classes.checkboxContainer}><input className={classes.checkbox} checked={props.isChecked} value={props.isChecked} onChange={props.onCheckedSlot} type='checkbox' /> {index + 1}</div></td>
        <td>
            <UnitInput name="x" onChange={(value) => props.onChange("x", value, index)}
                min={-500}
                value={slot.x}
                step={0.01}
                disabled={props.disabled}
                allowNegativeValue />
        </td>
        <td>
            <UnitInput name="y" onChange={(value) => props.onChange("y", value, index)}
                min={-500}
                step={0.01}
                value={slot.y}
                disabled={props.disabled}
                allowNegativeValue />
        </td>
        <td>
            <UnitInput name="width" onChange={(value) => props.onChange("width", value, index)}
                min={0}
                value={slot.width}
                disabled={props.disabled} />
        </td>
        <td>
            <UnitInput name="height" onChange={(value) => props.onChange("height", value, index)}
                min={0}
                value={slot.height}
                disabled={props.disabled} />
        </td>
        <td style={myStyleLeftCell}>
            <UnitInput name="rotation" onChange={(value) => props.onChange("rotation", value, index)}
                min={0}
                maxValue={359}
                value={slot.rotation}
                changedByCode
                disabled={props.disabled} />
        </td>
        <td style={myStyleRightCell}>
            <UnitInput name="x" onChange={(value) => props.onChangeImageBox("x", value, index)}
                min={-500}
                step={0.01}
                maxValue={slot.width}
                value={slot.imageBox && slot.imageBox.x}
                changedByCode
                disabled={props.disabled}
                allowNegativeValue />
        </td>
        <td>
            <UnitInput name="y" onChange={(value) => props.onChangeImageBox("y", value, index)}
                min={-500}
                step={0.01}
                maxValue={slot.height}
                value={slot.imageBox && slot.imageBox.y}
                changedByCode
                disabled={props.disabled}
                allowNegativeValue />
        </td>
        <td>
            <UnitInput name="width" onChange={(value) => props.onChangeImageBox("width", value, index)}
                min={0}
                maxValue={slot.width - slot.imageBox.x}
                value={slot.imageBox && slot.imageBox.width}
                changedByCode
                disabled={props.disabled} />
        </td>
        <td>
            <UnitInput name="height" onChange={(value) => props.onChangeImageBox("height", value, index)}
                min={0}
                maxValue={slot.height - slot.imageBox.y}
                value={slot.imageBox && slot.imageBox.height}
                changedByCode
                disabled={props.disabled} />
        </td>
        <td>
            <select name="rotation" onChange={(e) => props.onChangeImageBox("rotation", parseInt(e.target.value), index)}
                value={slot.imageBox && slot.imageBox.rotation} >
                <option value={0}> {t('editJig.slotEntry.selectNone')}</option>
                <option value={90}>90</option>
                <option value={180}>180</option>
                <option value={270}>270</option>
            </select>
        </td>
        <td>
            <select name="mirror" onChange={(e) => props.onChangeImageBox("mirror", e.target.value, index)}
                value={slot.imageBox && slot.imageBox.mirror} >
                <option value="NONE"> {t('editJig.slotEntry.selectNone')}</option>
                <option value="HORIZONTAL">{t('editJig.slotEntry.horizontal')}</option>
                <option value="VERTICAL">{t('editJig.slotEntry.vertical')}</option>
            </select>
        </td>
        <td>
            <select name="scale" onChange={(e) => props.onChangeImageBox("scale", e.target.value, index)}
                value={slot.imageBox && slot.imageBox.scale}>
                <option value="TRUE">{t('editJig.slotEntry.scaleOptionTrue')}</option>
                <option value="FIT">{t('editJig.slotEntry.scaleOptionFit')}</option>
                <option value="FILL">{t('editJig.slotEntry.scaleOptionFill')}</option>
                <option value="FIT_WIDTH">{t('editJig.slotEntry.scaleOptionFitWidth')}</option>
                <option value="FIT_HEIGHT">{t('editJig.slotEntry.scaleOptionFitHeight')}</option>
            </select>
        </td>
        <td className={classes.placement}>
            <ReferencePoint onChange={(position) => props.onChangeImageBox("placement", position, index)}
                placement={(slot.imageBox && slot.imageBox.placement) || "BOTTOM_RIGHT"} disabled={(slot.imageBox && slot.imageBox.scale === "FILL")} id={slot.id} />
        </td>
        <td>
            <div className={classes.actions}>
                <IconButton
                    onClick={() => props.onChange("delete", "NA", index)}
                    iconImage={trashOutlineImage}
                    tooltip={t('tooltip.delete')}
                    altText="Delete Slot" />
                <IconButton
                    onClick={() => props.onCopySlot(index)}
                    iconImage={copyOutlineImage}
                    tooltip={t('tooltip.copy')}
                    altText="Copy Slot" />

            </div>
        </td>
    </tr>
    )
}

export default EditJigItem