import React, { useState, useEffect } from "react";
import SideMenuItem from "./SideMenuItem";
import loginIcon from "../../assets/login-outline.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MessageDialog from "../MessageDialog/MessageDialog";
import { useTranslation } from "react-i18next";

function poptastic(url, clientId, redirectUrl, scope, responseType, nonce, responseMode) {
    const encode = window.encodeURIComponent;
    url = `${url}?client_id=${encode(clientId)}&redirect_uri=${encode(redirectUrl)}`;
    if (scope) {
        url += `&scope=${encode(scope)}`
    }
    if (responseType) {
        url += `&response_type=${encode(responseType)}`
    }
    if (nonce) {
        url += `&nonce=${encode(nonce)}`
    }
    if (responseMode) {
        url += `&response_mode=${encode(responseMode)}`
    }
	window.open (url, "auth", "width=700,height=900");
}
  
function SideMenuAuthItem({url, clientId, redirectUrl, scope, responseType, nonce, responseMode, callback}) {
    const history = useHistory();
    const t = useTranslation();
    const [showMessageDialog, setShowMessageDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState({
        title: "",
        message: "",
    });
    useEffect(() => {
        const onMessage = e => {
            if (e.origin === window.location.origin && e.source.name === 'auth') {
                callback && callback(e.data).then((response) => {
                    if (response && response.data && response.data.success) {
                    } else {
                        setDialogContent(() => {
                            return {
                                title: t("title.failed"),
                                message: t("signInPage.error"),
                            }
                        })
                        setShowMessageDialog(true);
                    }
                })
            }
        }
        window.addEventListener('message', onMessage);
        return () => {
            window.removeEventListener("message", onMessage);
        }
    }, [callback]);
    return <>
        <SideMenuItem 
            title={"Login Roland DG Connect admin account"} 
            type={"submit"}
            onClick={e => poptastic(url, clientId, redirectUrl, scope, responseType, nonce, responseMode)}
            imageIcon={loginIcon}
        />
        {showMessageDialog && <MessageDialog
            {...dialogContent}
            onCancel={() => setShowMessageDialog(false)}
            isCancel
            closeText={t("text.close")}
            width={"19rem"}
        />}
    </>
}

export default SideMenuAuthItem;