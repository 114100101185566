import React, { useRef } from "react";
import Consts from "../../Consts";
import { useContainerDimensions } from "../../custom-hooks/ContainerDimensions";
import Utils from "../../Utils";
import classes from './SlotPreview.module.css';
import boxAdornmentImage from '../../assets/box-adornment.png';

function SlotPreview(props) {
    const containerRef = useRef();
    const { width, height } = useContainerDimensions(containerRef);

    const slot = props.previewSlot;
    const validSlot = (slot && slot.width && slot.height); // defined and not zero

    // Compute the scaling from slot to preview coordinates.
    let scaling = 1.0;
    if (validSlot) {
        const rx = width / slot.width;
        const ry = height / slot.height;
        scaling = rx < ry ? rx : ry; // Fit the slot in available space
        if (scaling === 0) {
            // On first preview render, we get width and height of preview container as 0.
            // This causes scaling to become 0, and result in Infinity results dividing by scaling.
            // We set the scaling back to 1.0 here.
            scaling = 1.0;
        }
    }

    const strokeWeight = `${1 / scaling}px`;
    let placedWidth = 12 / scaling,
        placedHeight = 12 / scaling,
        paddingLeft = 5 / scaling,
        paddingTop = 5 / scaling;

    let getSlotMarkup = () => {
        if (slot.width && slot.height) {
            const strokeColor = Consts.SLOT_STROKE_COLOR;
            const strokeColorImageBox = Utils.isImageBoxInsideSlot(slot) ? Consts.IMAGE_BOX_STROKE_COLOR : Consts.STROKE_COLOR_WARN;

            let rotation = slot.imageBox.rotation || 0;
            rotation = (180 + rotation) % 360;

            // Compute the translation to bring order back into view post rotation.
            let rotateTransX = 0,
                rotateTransY = 0;
            if (rotation === 90) {
                rotateTransY = -placedWidth;
            }
            else if (rotation === 180) {
                rotateTransX = -placedWidth;
                rotateTransY = -placedHeight;
            }
            else if (rotation === 270) {
                rotateTransX = -placedHeight;
            }

            // Compute the translation to bring order image back into view post mirroring.
            let mirrorScaleX = 1,
                mirrorScaleY = 1,
                mirrorTransX = 0,
                mirrorTransY = 0;

            if (slot.imageBox.mirror === "HORIZONTAL") {
                mirrorScaleX = -1;
                mirrorTransX = -placedWidth;
            }
            else if (slot.imageBox.mirror === "VERTICAL") {
                mirrorScaleY = -1;
                mirrorTransY = -placedHeight;
            }

            let toImageX = slot.imageBox.x;
            let toImageY = slot.imageBox.y;

            let [ypos, xpos] = slot.imageBox.placement.split("_");

            if (ypos === "BOTTOM") {
                toImageY += paddingTop;
            } else if (ypos === "MIDDLE") {
                toImageY += ((slot.imageBox.height - placedHeight) / 2);
            } else {
                toImageY += (slot.imageBox.height - placedHeight - paddingTop);
            }

            if (xpos === "LEFT") {
                toImageX += (slot.imageBox.width - placedWidth - paddingLeft);
            } else if (xpos === "CENTER") {
                toImageX += ((slot.imageBox.width - placedWidth) / 2);
            } else {
                toImageX += paddingLeft;
            }

            const imageBoxPath = Utils.getSlotShapeMarkup(slot.imageBox, Consts.IMAGE_BOX_FILL_COLOR, strokeColorImageBox, strokeWeight);
            let clipPathId = "mask"
            return (
                <>
                    {Utils.getSlotShapeMarkup(slot, Consts.SLOT_FILL_COLOR, strokeColor, strokeWeight)}
                    <g transform={`translate(${0} ${0})`}>
                        <defs>
                            <clipPath id={clipPathId}>
                                {imageBoxPath}
                            </clipPath>
                        </defs>
                        {imageBoxPath}
                        <g clipPath={`url(#${clipPathId})`}>
                            <g transform={`translate(${toImageX}, ${toImageY})`}>
                                {/* First rotate then mirror the image*/}
                                <g transform={`scale(${mirrorScaleX} ${mirrorScaleY}) translate(${mirrorTransX}, ${mirrorTransY})`}>
                                    <image href={boxAdornmentImage} transform={`rotate(${rotation}) translate(${rotateTransX}, ${rotateTransY})`} width={placedWidth} height={placedHeight} />
                                </g>
                            </g>
                        </g>
                    </g>
                </>
            );
        }
        return null;
    };

    const scaledSlotWidth = scaling * slot.width;
    const scaledSlotHeight = scaling * slot.height;


    return (
        <div className={classes.previewContainer} ref={containerRef}>
            {validSlot ?
                <svg width={scaledSlotWidth + 10} height={scaledSlotHeight + 10}>
                    <g transform={`scale(${scaling})`}>
                        <g transform={`scale(-1) translate(${-slot.width}, ${-slot.height})`}>
                            {getSlotMarkup()}
                            {props.showOrigin && Utils.getOriginMarkerMarkup(scaling)}
                        </g>
                    </g>
                </svg> : ''}
        </div>
    );
}

export default SlotPreview;