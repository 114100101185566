import axios from "axios";
import Utils from "../../Utils";
import { usersActions } from "../Users/UsersSlice";

export async function getUserStorageQuota(dispatch) {
    if (!localStorage.getItem("isRdgUser")) {
        return {};
    }

    return axios({
        url: '/pax/storage/getUserStorage',
        method: 'post',
        responseType: 'json',
        data: {}
    }).then((res) => {
        if (res.data?.data) {
            const data = res.data.data;
            const totalUsage = Number(data.total_usage);
            const subscStorage = Number(data.subsc_storage);
            const percentStorageUsed = subscStorage !== 0 ? (totalUsage / subscStorage) * 100 : 0;
            if (subscStorage === 0){
              
            }
            if (percentStorageUsed >= 100) {

                dispatch(usersActions.updateUserStorageFull(true));
                
            } else {
                dispatch(usersActions.updateUserStorageFull(false));
            }
            return {
              percentStorageUsed,
              totalStorage: subscStorage,
              storageUsed: totalUsage
            }
        }
    }).catch(function (error) {
        console.log(error);
    });
}

export async function getUpdatedUserStorage(userId) {
    if (!localStorage.getItem("isRdgUser")) return;
    if (!userId) return;
    return axios({
      url: '/pax/storage/getUpdatedUserStorage',
      method: "post",
      data: {
        userId
      }
    })
      .then((res) => {
        if (res.data.data) {
            return localStorage.getItem("isRdgUser") ? updateUserStorageQuota(res.data.data) : null;
        }
      })
      .catch(err => {})
  }
  
  export async function updateUserStorageQuota(storageUsed) {
    if (!localStorage.getItem("isRdgUser")) return;
    return axios({  
      url: `pax/storage/updateUserStorage`,
      method: "post",
      data: {
        storageUsed
      }
    }).then((res) => {
  
    }).catch(err => {})
  }