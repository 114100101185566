import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Utils from "../../../Utils";
import { loginUser, loginUsingToken, logoutUser } from "../../Auth/AuthActions";
import LoginForm from "../../Auth/components/LoginForm";
import Header from "../../../components/Header/Header";
import classes from "./Login.module.css";
import SideMenu from "../../../components/SideMenu/SideMenu";
import SideMenuAuthItem from "../../../components/SideMenu/SideMenuAuthItem";
import SideMenuItem from "../../../components/SideMenu/SideMenuItem";
import Consts from "../../../Consts";
import setupGuideIcon from "../../../assets/setup-guide-outline.svg";
import LinkButton from "../../../components/Buttons/LinkButton";

function Login({ setShowPrivacy }) {
  const [showError, setShowEror] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();
  const [environmentName, setEnvironmentName] = useState(
    window.getResourceName()
  );
  const [type, setType] = useState("");
  useEffect(() => {
    window.setResourceName(environmentName);
    setType(environmentName ? "b2c" : "b2b");
  }, [environmentName]);

  const loginHandler = (userId, password, type) => {
    const creds = {
      userId: userId,
      password: password,
    };
    loginUser(creds, type).then((response) => {
      if (response && response.data) {
        if (response.data.success) {
          setShowEror(false);
          localStorage.setItem("type", type);
        } else {
          setShowEror(true);
          setErrorMessage(t("login.wrongCredentials"));
        }
      } else {
        setShowEror(true);
        setErrorMessage(t("login.failedRequest"));
      }
    });
  };

  function errorDialogeCloseHandler() {
    setShowEror(false);
  }

  useEffect(() => {
    logoutUser(true);
  }, []);

  return (
    <section>
      <Header logoutUser={logoutUser} />
      {showError &&
        Utils.showErrorDialog(errorMessage, errorDialogeCloseHandler)}
      <div className={classes.menuContainer}>
        <div className={classes.menu}>
          <SideMenu>
            {type === "b2c" ? (
              <SideMenuAuthItem
                url="https://rdgconnect.b2clogin.com/rdgconnect.onmicrosoft.com/B2C_1_SIGNIN_PAX_APP/oauth2/v2.0/authorize"
                clientId="25e3ab7a-df9a-4383-894d-9cfbc4a72f3a"
                scope="https://rdgconnect.onmicrosoft.com/func-rdgconnect-paxsi/user_impersonation"
                responseType="token"
                redirectUrl={`${window.location.origin}/b2c/redirect.html`}
                nonce="b2c"
                responseMode="fragment"
                callback={(data) => loginUsingToken(data.token, "b2c")}
              />
            ) : (
              <SideMenuAuthItem
                url="https://login.microsoftonline.com/365rolanddg.onmicrosoft.com/oauth2/v2.0/authorize"
                clientId="fd0f113c-4071-4a98-9173-8203b3fb61a0"
                scope="https://rolanddg.com/func-rdgconnect-paxsi/user_impersonation"
                responseType="token"
                redirectUrl={`${window.location.origin}/b2b/redirect.html`}
                nonce="b2b"
                responseMode="fragment"
                callback={(data) => loginUsingToken(data.token, "b2b")}
              />
            )}
            <SideMenuItem
              title={"Setup Guide"}
              onClick={() => window.open(Consts.SETUP_GUIDE_LINK, "_blank")}
              imageIcon={setupGuideIcon}
            />
          </SideMenu>
        </div>
      </div>
      <div className={classes.loginPageContainer}>
        <div className={classes.htmlViewerContainer}>
          <div className={classes.htmlViewer}>
            <iframe src="/marketing/marketing.html"></iframe>
          </div>
          <LinkButton
            style={{ marginTop: "2rem" }}
            onClick={(e) => setShowPrivacy && setShowPrivacy(true)}
          >
            {t("link.privacyPolicy")}
          </LinkButton>
        </div>
        <LoginForm
          handleLogin={loginHandler}
          type={type}
          environmentName={environmentName}
          setEnvironmentName={setEnvironmentName}
        />
      </div>
      <span className={classes.version}>v 2.2.0.0</span>
    </section>
  );
}

export default Login;
