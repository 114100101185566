import Utils from "./Utils";

let fabUtils = {};

(function () {
    function find(array, byProperty, condition) {
        if (!array || array.length === 0) {
            return;
        }

        let i = array.length - 1,
            result = byProperty ? array[i][byProperty] : array[i];
        if (byProperty) {
            while (i--) {
                if (condition(array[i][byProperty], result)) {
                    result = array[i][byProperty];
                }
            }
        }
        else {
            while (i--) {
                if (condition(array[i], result)) {
                    result = array[i];
                }
            }
        }
        return result;
    }
    const PiBy180 = Math.PI / 180,
        PiBy2 = Math.PI / 2,
        commandLengths = {
            m: 2,
            l: 2,
            h: 1,
            v: 1,
            c: 6,
            s: 4,
            q: 4,
            t: 2,
            a: 7
        },
        repeatedCommands = {
            m: 'l',
            M: 'L'
        };

    fabUtils = {
        array: {
            max: function (array, byProperty) {
                return find(array, byProperty, function (value1, value2) {
                    return value1 >= value2;
                });
            },
            min: function (array, byProperty) {
                return find(array, byProperty, function (value1, value2) {
                    return value1 < value2;
                });
            }
        },
        cos: function (angle) {
            if (angle === 0) { return 1; }
            if (angle < 0) {
                angle = -angle;
            }
            let angleSlice = angle / PiBy2;
            switch (angleSlice) {
                case 1: case 3: return 0;
                case 2: return -1;
                default: return Math.cos(angle);
            }
        },
        sin: function (angle) {
            if (angle === 0) { return 0; }
            let angleSlice = angle / PiBy2, sign = 1;
            if (angle < 0) {
                sign = -1;
            }
            switch (angleSlice) {
                case 1: return sign;
                case 2: return 0;
                case 3: return -sign;
                default: return Math.sin(angle);
            }
        },

        degreesToRadians: function (degrees) {
            return degrees * PiBy180;
        },
        parseDimensions: function (path) {
            let aX = [],
                aY = [],
                current, // current instruction
                previous = null,
                subpathStartX = 0,
                subpathStartY = 0,
                x = 0, // current x
                y = 0, // current y
                controlX = 0, // current control point x
                controlY = 0, // current control point y
                tempX,
                tempY,
                bounds;

            for (let i = 0, len = path.length; i < len; ++i) {
                current = path[i];
                switch (current[0]) { // first letter
                    case 'l': // lineto, relative
                        x += current[1];
                        y += current[2];
                        bounds = [];
                        break;

                    case 'L': // lineto, absolute
                        x = current[1];
                        y = current[2];
                        bounds = [];
                        break;

                    case 'h': // horizontal lineto, relative
                        x += current[1];
                        bounds = [];
                        break;

                    case 'H': // horizontal lineto, absolute
                        x = current[1];
                        bounds = [];
                        break;

                    case 'v': // vertical lineto, relative
                        y += current[1];
                        bounds = [];
                        break;

                    case 'V': // verical lineto, absolute
                        y = current[1];
                        bounds = [];
                        break;

                    case 'm': // moveTo, relative
                        x += current[1];
                        y += current[2];
                        subpathStartX = x;
                        subpathStartY = y;
                        bounds = [];
                        break;

                    case 'M': // moveTo, absolute
                        x = current[1];
                        y = current[2];
                        subpathStartX = x;
                        subpathStartY = y;
                        bounds = [];
                        break;

                    case 'c': // bezierCurveTo, relative
                        tempX = x + current[5];
                        tempY = y + current[6];
                        controlX = x + current[3];
                        controlY = y + current[4];
                        bounds = fabUtils.getBoundsOfCurve(x, y,
                            x + current[1], // x1
                            y + current[2], // y1
                            controlX, // x2
                            controlY, // y2
                            tempX,
                            tempY
                        );
                        x = tempX;
                        y = tempY;
                        break;

                    case 'C': // bezierCurveTo, absolute
                        controlX = current[3];
                        controlY = current[4];
                        bounds = fabUtils.getBoundsOfCurve(x, y,
                            current[1],
                            current[2],
                            controlX,
                            controlY,
                            current[5],
                            current[6]
                        );
                        x = current[5];
                        y = current[6];
                        break;

                    case 's': // shorthand cubic bezierCurveTo, relative

                        // transform to absolute x,y
                        tempX = x + current[3];
                        tempY = y + current[4];

                        if (previous[0].match(/[CcSs]/) === null) {
                            // If there is no previous command or if the previous command was not a C, c, S, or s,
                            // the control point is coincident with the current point
                            controlX = x;
                            controlY = y;
                        }
                        else {
                            // calculate reflection of previous control points
                            controlX = 2 * x - controlX;
                            controlY = 2 * y - controlY;
                        }

                        bounds = fabUtils.getBoundsOfCurve(x, y,
                            controlX,
                            controlY,
                            x + current[1],
                            y + current[2],
                            tempX,
                            tempY
                        );
                        // set control point to 2nd one of this command
                        // "... the first control point is assumed to be
                        // the reflection of the second control point on
                        // the previous command relative to the current point."
                        controlX = x + current[1];
                        controlY = y + current[2];
                        x = tempX;
                        y = tempY;
                        break;

                    case 'S': // shorthand cubic bezierCurveTo, absolute
                        tempX = current[3];
                        tempY = current[4];
                        if (previous[0].match(/[CcSs]/) === null) {
                            // If there is no previous command or if the previous command was not a C, c, S, or s,
                            // the control point is coincident with the current point
                            controlX = x;
                            controlY = y;
                        }
                        else {
                            // calculate reflection of previous control points
                            controlX = 2 * x - controlX;
                            controlY = 2 * y - controlY;
                        }
                        bounds = fabUtils.getBoundsOfCurve(x, y,
                            controlX,
                            controlY,
                            current[1],
                            current[2],
                            tempX,
                            tempY
                        );
                        x = tempX;
                        y = tempY;
                        // set control point to 2nd one of this command
                        // "... the first control point is assumed to be
                        // the reflection of the second control point on
                        // the previous command relative to the current point."
                        controlX = current[1];
                        controlY = current[2];
                        break;

                    case 'q': // quadraticCurveTo, relative
                        // transform to absolute x,y
                        tempX = x + current[3];
                        tempY = y + current[4];
                        controlX = x + current[1];
                        controlY = y + current[2];
                        bounds = fabUtils.getBoundsOfCurve(x, y,
                            controlX,
                            controlY,
                            controlX,
                            controlY,
                            tempX,
                            tempY
                        );
                        x = tempX;
                        y = tempY;
                        break;

                    case 'Q': // quadraticCurveTo, absolute
                        controlX = current[1];
                        controlY = current[2];
                        bounds = fabUtils.getBoundsOfCurve(x, y,
                            controlX,
                            controlY,
                            controlX,
                            controlY,
                            current[3],
                            current[4]
                        );
                        x = current[3];
                        y = current[4];
                        break;

                    case 't': // shorthand quadraticCurveTo, relative
                        // transform to absolute x,y
                        tempX = x + current[1];
                        tempY = y + current[2];
                        if (previous[0].match(/[QqTt]/) === null) {
                            // If there is no previous command or if the previous command was not a Q, q, T or t,
                            // assume the control point is coincident with the current point
                            controlX = x;
                            controlY = y;
                        }
                        else {
                            // calculate reflection of previous control point
                            controlX = 2 * x - controlX;
                            controlY = 2 * y - controlY;
                        }

                        bounds = fabUtils.getBoundsOfCurve(x, y,
                            controlX,
                            controlY,
                            controlX,
                            controlY,
                            tempX,
                            tempY
                        );
                        x = tempX;
                        y = tempY;

                        break;

                    case 'T':
                        tempX = current[1];
                        tempY = current[2];

                        if (previous[0].match(/[QqTt]/) === null) {
                            // If there is no previous command or if the previous command was not a Q, q, T or t,
                            // assume the control point is coincident with the current point
                            controlX = x;
                            controlY = y;
                        }
                        else {
                            // calculate reflection of previous control point
                            controlX = 2 * x - controlX;
                            controlY = 2 * y - controlY;
                        }
                        bounds = fabUtils.getBoundsOfCurve(x, y,
                            controlX,
                            controlY,
                            controlX,
                            controlY,
                            tempX,
                            tempY
                        );
                        x = tempX;
                        y = tempY;
                        break;

                    case 'a':
                        // TODO: optimize this
                        bounds = fabUtils.getBoundsOfArc(x, y,
                            current[1],
                            current[2],
                            current[3],
                            current[4],
                            current[5],
                            current[6] + x,
                            current[7] + y
                        );
                        x += current[6];
                        y += current[7];
                        break;

                    case 'A':
                        // TODO: optimize this
                        bounds = fabUtils.getBoundsOfArc(x, y,
                            current[1],
                            current[2],
                            current[3],
                            current[4],
                            current[5],
                            current[6],
                            current[7]
                        );
                        x = current[6];
                        y = current[7];
                        break;

                    case 'z':
                    case 'Z':
                        x = subpathStartX;
                        y = subpathStartY;
                        break;

                    default:
                        break;
                }
                previous = current;
                bounds.forEach(function (point) {
                    aX.push(point.x);
                    aY.push(point.y);
                });
                aX.push(x);
                aY.push(y);
            }

            let minX = fabUtils.array.min(aX) || 0,
                minY = fabUtils.array.min(aY) || 0,
                maxX = fabUtils.array.max(aX) || 0,
                maxY = fabUtils.array.max(aY) || 0,
                deltaX = maxX - minX,
                deltaY = maxY - minY,

                o = {
                    left: minX,
                    top: minY,
                    width: deltaX,
                    height: deltaY
                };

            return o;
        },

        multiplyTransformMatrices: function (a, b, is2x2) {
            return [
                a[0] * b[0] + a[2] * b[1],
                a[1] * b[0] + a[3] * b[1],
                a[0] * b[2] + a[2] * b[3],
                a[1] * b[2] + a[3] * b[3],
                is2x2 ? 0 : a[0] * b[4] + a[2] * b[5] + a[4],
                is2x2 ? 0 : a[1] * b[4] + a[3] * b[5] + a[5]
            ];
        },

        radiansToDegrees: function (radians) {
            return radians / PiBy180;
        },
        rePathCommand: /([-+]?((\d+\.\d+)|((\d+)|(\.\d+)))(?:[eE][-+]?\d+)?)/gi,
        reNum: '(?:[-+]?(?:\\d+|\\d*\\.\\d+)(?:[eE][-+]?\\d+)?)',
        commaWsp: '(?:\\s+,?\\s*|,\\s*)',
        iMatrix: [1, 0, 0, 1, 0, 0],
        parsePath: function (pathString) {
            let result = [],
                coords = [],
                currentPath,
                parsed,
                re = this.rePathCommand,
                rNumber = '[-+]?(?:\\d*\\.\\d+|\\d+\\.?)(?:[eE][-+]?\\d+)?\\s*',
                rNumberCommaWsp = '(' + rNumber + ')' + this.commaWsp,
                rFlagCommaWsp = '([01])' + this.commaWsp + '?',
                rArcSeq = rNumberCommaWsp + '?' + rNumberCommaWsp + '?' + rNumberCommaWsp + rFlagCommaWsp + rFlagCommaWsp +
                    rNumberCommaWsp + '?(' + rNumber + ')',
                regArcArgumentSequence = new RegExp(rArcSeq, 'g'),
                match,
                coordsStr,
                // one of commands (m,M,l,L,q,Q,c,C,etc.) followed by non-command characters (i.e. command values)
                path;
            if (!pathString || !pathString.match) {
                return result;
            }
            path = pathString.match(/[mzlhvcsqta][^mzlhvcsqta]*/gi);

            for (let i = 0, coordsParsed, len = path.length; i < len; i++) {
                currentPath = path[i];

                coordsStr = currentPath.slice(1).trim();
                coords.length = 0;

                let command = currentPath.charAt(0);
                coordsParsed = [command];

                if (command.toLowerCase() === 'a') {
                    // arcs have special flags that apparently don't require spaces so handle special
                    for (let args; (args = regArcArgumentSequence.exec(coordsStr));) {
                        for (let j = 1; j < args.length; j++) {
                            coords.push(args[j]);
                        }
                    }
                }
                else {
                    while ((match = re.exec(coordsStr))) {
                        coords.push(match[0]);
                    }
                }

                for (let j = 0, jlen = coords.length; j < jlen; j++) {
                    parsed = parseFloat(coords[j]);
                    if (!isNaN(parsed)) {
                        coordsParsed.push(parsed);
                    }
                }

                let commandLength = commandLengths[command.toLowerCase()],
                    repeatedCommand = repeatedCommands[command] || command;

                if (coordsParsed.length - 1 > commandLength) {
                    for (let k = 1, klen = coordsParsed.length; k < klen; k += commandLength) {
                        result.push([command].concat(coordsParsed.slice(k, k + commandLength)));
                        command = repeatedCommand;
                    }
                }
                else {
                    result.push(coordsParsed);
                }
            }
            return result;
        }
    };
})();
(function () {
    let arcToSegmentsCache = {},
        segmentToBezierCache = {},
        boundsOfCurveCache = {},
        _join = Array.prototype.join;
    function arcToSegments(toX, toY, rx, ry, large, sweep, rotateX) {
        let argsString = _join.call(arguments);
        if (arcToSegmentsCache[argsString]) {
            return arcToSegmentsCache[argsString];
        }

        let PI = Math.PI, th = rotateX * PI / 180,
            sinTh = fabUtils.sin(th),
            cosTh = fabUtils.cos(th),
            fromX = 0, fromY = 0;

        rx = Math.abs(rx);
        ry = Math.abs(ry);

        let px = -cosTh * toX * 0.5 - sinTh * toY * 0.5,
            py = -cosTh * toY * 0.5 + sinTh * toX * 0.5,
            rx2 = rx * rx, ry2 = ry * ry, py2 = py * py, px2 = px * px,
            pl = rx2 * ry2 - rx2 * py2 - ry2 * px2,
            root = 0;

        if (pl < 0) {
            let s = Math.sqrt(1 - pl / (rx2 * ry2));
            rx *= s;
            ry *= s;
        }
        else {
            root = (large === sweep ? -1.0 : 1.0) *
                Math.sqrt(pl / (rx2 * py2 + ry2 * px2));
        }

        let cx = root * rx * py / ry,
            cy = -root * ry * px / rx,
            cx1 = cosTh * cx - sinTh * cy + toX * 0.5,
            cy1 = sinTh * cx + cosTh * cy + toY * 0.5,
            mTheta = calcVectorAngle(1, 0, (px - cx) / rx, (py - cy) / ry),
            dtheta = calcVectorAngle((px - cx) / rx, (py - cy) / ry, (-px - cx) / rx, (-py - cy) / ry);

        if (sweep === 0 && dtheta > 0) {
            dtheta -= 2 * PI;
        }
        else if (sweep === 1 && dtheta < 0) {
            dtheta += 2 * PI;
        }

        // Convert into cubic bezier segments <= 90deg
        let segments = Math.ceil(Math.abs(dtheta / PI * 2)),
            result = [], mDelta = dtheta / segments,
            mT = 8 / 3 * Math.sin(mDelta / 4) * Math.sin(mDelta / 4) / Math.sin(mDelta / 2),
            th3 = mTheta + mDelta;

        for (let i = 0; i < segments; i++) {
            result[i] = segmentToBezier(mTheta, th3, cosTh, sinTh, rx, ry, cx1, cy1, mT, fromX, fromY);
            fromX = result[i][4];
            fromY = result[i][5];
            mTheta = th3;
            th3 += mDelta;
        }
        arcToSegmentsCache[argsString] = result;
        return result;
    }

    function segmentToBezier(th2, th3, cosTh, sinTh, rx, ry, cx1, cy1, mT, fromX, fromY) {
        let argsString2 = _join.call(arguments);
        if (segmentToBezierCache[argsString2]) {
            return segmentToBezierCache[argsString2];
        }

        let costh2 = fabUtils.cos(th2),
            sinth2 = fabUtils.sin(th2),
            costh3 = fabUtils.cos(th3),
            sinth3 = fabUtils.sin(th3),
            toX = cosTh * rx * costh3 - sinTh * ry * sinth3 + cx1,
            toY = sinTh * rx * costh3 + cosTh * ry * sinth3 + cy1,
            cp1X = fromX + mT * (-cosTh * rx * sinth2 - sinTh * ry * costh2),
            cp1Y = fromY + mT * (-sinTh * rx * sinth2 + cosTh * ry * costh2),
            cp2X = toX + mT * (cosTh * rx * sinth3 + sinTh * ry * costh3),
            cp2Y = toY + mT * (sinTh * rx * sinth3 - cosTh * ry * costh3);

        segmentToBezierCache[argsString2] = [
            cp1X, cp1Y,
            cp2X, cp2Y,
            toX, toY
        ];
        return segmentToBezierCache[argsString2];
    }

    /*
        * Private
        */
    function calcVectorAngle(ux, uy, vx, vy) {
        let ta = Math.atan2(uy, ux),
            tb = Math.atan2(vy, vx);
        if (tb >= ta) {
            return tb - ta;
        }
        else {
            return 2 * Math.PI - (ta - tb);
        }
    }

    /**
     * Draws arc
     * @param {CanvasRenderingContext2D} ctx
     * @param {Number} fx
     * @param {Number} fy
     * @param {Array} coords
     */
    fabUtils.drawArc = function (ctx, fx, fy, coords) {
        let rx = coords[0],
            ry = coords[1],
            rot = coords[2],
            large = coords[3],
            sweep = coords[4],
            tx = coords[5],
            ty = coords[6],
            segs = [[], [], [], []],
            segsNorm = arcToSegments(tx - fx, ty - fy, rx, ry, large, sweep, rot);

        for (let i = 0, len = segsNorm.length; i < len; i++) {
            segs[i][0] = segsNorm[i][0] + fx;
            segs[i][1] = segsNorm[i][1] + fy;
            segs[i][2] = segsNorm[i][2] + fx;
            segs[i][3] = segsNorm[i][3] + fy;
            segs[i][4] = segsNorm[i][4] + fx;
            segs[i][5] = segsNorm[i][5] + fy;
            ctx.bezierCurveTo.apply(ctx, segs[i]);
        }
    };

    /**
     * Calculate bounding box of a elliptic-arc
     * @param {Number} fx start point of arc
     * @param {Number} fy
     * @param {Number} rx horizontal radius
     * @param {Number} ry vertical radius
     * @param {Number} rot angle of horizontal axe
     * @param {Number} large 1 or 0, whatever the arc is the big or the small on the 2 points
     * @param {Number} sweep 1 or 0, 1 clockwise or counterclockwise direction
     * @param {Number} tx end point of arc
     * @param {Number} ty
     */
    fabUtils.getBoundsOfArc = function (fx, fy, rx, ry, rot, large, sweep, tx, ty) {

        let fromX = 0, fromY = 0, bound, bounds = [],
            segs = arcToSegments(tx - fx, ty - fy, rx, ry, large, sweep, rot);

        for (let i = 0, len = segs.length; i < len; i++) {
            bound = getBoundsOfCurve(fromX, fromY, segs[i][0], segs[i][1], segs[i][2], segs[i][3], segs[i][4], segs[i][5]);
            bounds.push({ x: bound[0].x + fx, y: bound[0].y + fy });
            bounds.push({ x: bound[1].x + fx, y: bound[1].y + fy });
            fromX = segs[i][4];
            fromY = segs[i][5];
        }
        return bounds;
    };

    /**
     * Calculate bounding box of a beziercurve
     * @param {Number} x0 starting point
     * @param {Number} y0
     * @param {Number} x1 first control point
     * @param {Number} y1
     * @param {Number} x2 secondo control point
     * @param {Number} y2
     * @param {Number} x3 end of beizer
     * @param {Number} y3
     */
    // taken from http://jsbin.com/ivomiq/56/edit  no credits available for that.
    function getBoundsOfCurve(x0, y0, x1, y1, x2, y2, x3, y3) {
        let argsString = _join.call(arguments);
        if (boundsOfCurveCache[argsString]) {
            return boundsOfCurveCache[argsString];
        }

        let sqrt = Math.sqrt,
            min = Math.min, max = Math.max,
            abs = Math.abs, tvalues = [],
            bounds = [[], []],
            a, b, c, t, t1, t2, b2ac, sqrtb2ac;

        b = 6 * x0 - 12 * x1 + 6 * x2;
        a = -3 * x0 + 9 * x1 - 9 * x2 + 3 * x3;
        c = 3 * x1 - 3 * x0;

        for (let i = 0; i < 2; ++i) {
            if (i > 0) {
                b = 6 * y0 - 12 * y1 + 6 * y2;
                a = -3 * y0 + 9 * y1 - 9 * y2 + 3 * y3;
                c = 3 * y1 - 3 * y0;
            }

            if (abs(a) < 1e-12) {
                if (abs(b) < 1e-12) {
                    continue;
                }
                t = -c / b;
                if (0 < t && t < 1) {
                    tvalues.push(t);
                }
                continue;
            }
            b2ac = b * b - 4 * c * a;
            if (b2ac < 0) {
                continue;
            }
            sqrtb2ac = sqrt(b2ac);
            t1 = (-b + sqrtb2ac) / (2 * a);
            if (0 < t1 && t1 < 1) {
                tvalues.push(t1);
            }
            t2 = (-b - sqrtb2ac) / (2 * a);
            if (0 < t2 && t2 < 1) {
                tvalues.push(t2);
            }
        }

        let x, y, j = tvalues.length, jlen = j, mt;
        while (j--) {
            t = tvalues[j];
            mt = 1 - t;
            x = (mt * mt * mt * x0) + (3 * mt * mt * t * x1) + (3 * mt * t * t * x2) + (t * t * t * x3);
            bounds[0][j] = x;

            y = (mt * mt * mt * y0) + (3 * mt * mt * t * y1) + (3 * mt * t * t * y2) + (t * t * t * y3);
            bounds[1][j] = y;
        }

        bounds[0][jlen] = x0;
        bounds[1][jlen] = y0;
        bounds[0][jlen + 1] = x3;
        bounds[1][jlen + 1] = y3;
        let result = [
            {
                x: min.apply(null, bounds[0]),
                y: min.apply(null, bounds[1])
            },
            {
                x: max.apply(null, bounds[0]),
                y: max.apply(null, bounds[1])
            }
        ];
        boundsOfCurveCache[argsString] = result;
        return result;
    }

    fabUtils.getBoundsOfCurve = getBoundsOfCurve;
})();


fabUtils.parseTransformAttribute = (function () {
    function rotateMatrix(matrix, args) {
        let cos = fabUtils.cos(args[0]), sin = fabUtils.sin(args[0]),
            x = 0, y = 0;
        if (args.length === 3) {
            x = args[1];
            y = args[2];
        }

        matrix[0] = cos;
        matrix[1] = sin;
        matrix[2] = -sin;
        matrix[3] = cos;
        matrix[4] = x - (cos * x - sin * y);
        matrix[5] = y - (sin * x + cos * y);
    }

    function scaleMatrix(matrix, args) {
        let multiplierX = args[0],
            multiplierY = (args.length === 2) ? args[1] : args[0];

        matrix[0] = multiplierX;
        matrix[3] = multiplierY;
    }

    function skewMatrix(matrix, args, pos) {
        matrix[pos] = Math.tan(fabUtils.degreesToRadians(args[0]));
    }

    function translateMatrix(matrix, args) {
        matrix[4] = args[0];
        if (args.length === 2) {
            matrix[5] = args[1];
        }
    }

    // identity matrix
    let iMatrix = fabUtils.iMatrix,
        number = fabUtils.reNum,
        commaWsp = fabUtils.commaWsp,
        skewX = '(?:(skewX)\\s*\\(\\s*(' + number + ')\\s*\\))',
        skewY = '(?:(skewY)\\s*\\(\\s*(' + number + ')\\s*\\))',
        rotate = '(?:(rotate)\\s*\\(\\s*(' + number + ')(?:' +
            commaWsp + '(' + number + ')' +
            commaWsp + '(' + number + '))?\\s*\\))',
        scale = '(?:(scale)\\s*\\(\\s*(' + number + ')(?:' +
            commaWsp + '(' + number + '))?\\s*\\))',
        translate = '(?:(translate)\\s*\\(\\s*(' + number + ')(?:' +
            commaWsp + '(' + number + '))?\\s*\\))',
        matrix = '(?:(matrix)\\s*\\(\\s*' +
            '(' + number + ')' + commaWsp +
            '(' + number + ')' + commaWsp +
            '(' + number + ')' + commaWsp +
            '(' + number + ')' + commaWsp +
            '(' + number + ')' + commaWsp +
            '(' + number + ')' +
            '\\s*\\))',
        transform = '(?:' +
            matrix + '|' +
            translate + '|' +
            scale + '|' +
            rotate + '|' +
            skewX + '|' +
            skewY +
            ')',
        transforms = `(?:${transform}(?:${commaWsp}*${transform})*)`,
        transformList = '^\\s*(?:' + transforms + '?)\\s*$',
        reTransformList = new RegExp(transformList),
        reTransform = new RegExp(transform, 'g');

    return function (attributeValue) {
        let matrix = iMatrix.concat(),
            matrices = [];
        if (!attributeValue || (attributeValue && !reTransformList.test(attributeValue))) {
            return matrix;
        }

        attributeValue.replace(reTransform, function (match) {

            let m = new RegExp(transform).exec(match).filter(function (match) {
                // match !== '' && match != null
                return (!!match);
            }),
                operation = m[1],
                args = m.slice(2).map(parseFloat);

            switch (operation) {
                case 'translate':
                    translateMatrix(matrix, args);
                    break;
                case 'rotate':
                    args[0] = fabUtils.degreesToRadians(args[0]);
                    rotateMatrix(matrix, args);
                    break;
                case 'scale':
                    scaleMatrix(matrix, args);
                    break;
                case 'skewX':
                    skewMatrix(matrix, args, 2);
                    break;
                case 'skewY':
                    skewMatrix(matrix, args, 1);
                    break;
                case 'matrix':
                    matrix = args;
                    break;
                default:
                    break;
            }

            // snapshot current matrix into matrices array
            matrices.push(matrix.concat());
            // reset
            matrix = iMatrix.concat();
        });

        let combinedMatrix = matrices[0];
        while (matrices.length > 1) {
            matrices.shift();
            combinedMatrix = fabUtils.multiplyTransformMatrices(combinedMatrix, matrices[0]);
        }
        return combinedMatrix;
    };
})();

const SvgParserError = {
    SUCCESS: 0,
    ERROR_PARSE_FAILED: 1,
    ERROR_SLOTS_EXCEED_JIG: 2,
    ERROR_PRINTS_EXCEED_SLOT: 3
};

function SvgParserException(errorCode) {
    this.errorCode = errorCode;
}

const svgParser = {
    getSvgDocument: function (svgStr) {
        let svgDoc;
        if (!svgStr || typeof svgStr !== 'string') {
            throw new SvgParserException(SvgParserError.ERROR_PARSE_FAILED);
        }
        // Parse the svg data, treating as generic xml.
        try {
            let parser = new DOMParser();
            svgDoc = parser.parseFromString(svgStr, "text/xml");
        } catch (e) {
            throw new SvgParserException(SvgParserError.ERROR_PARSE_FAILED);
        }
        // Error handling for any parsing errors.
        if (!svgDoc || !svgDoc.documentElement ||
            svgDoc.getElementsByTagName('parsererror').length) {
            throw new SvgParserException(SvgParserError.ERROR_PARSE_FAILED);
        }
        return svgDoc;
    },

    parseJigSvg: function (svgDoc) {
        // Read in the width, height, and viewbox to establish the
        // svg coordinate space.
        let viewBox;

        let svgElem = svgDoc.documentElement;
        if (svgElem.hasAttribute('viewBox')) {
            let viewBoxAttr = svgElem.getAttribute('viewBox');
            viewBox = viewBoxAttr.split(' ').map(function (v) {
                return Number(v);
            });
        } else {
            throw new SvgParserException(SvgParserError.ERROR_PARSE_FAILED);
        }

        let svgWidth, svgHeight;
        let widthUnits = 'px';
        let heightUnits = 'px';

        let expr = /(\d*\.?\d*)(.*)/;

        if (svgElem.hasAttribute('width')) {
            let widthAttr = svgElem.getAttribute('width');
            let widthAttrSplit = widthAttr.match(expr);

            // match(expr) yields ['11.1px', '11.1', 'px'] for '11.1px'
            svgWidth = Number(widthAttrSplit[1]);
            widthUnits = widthAttrSplit[2];
        } else {
            svgWidth = viewBox[2];
        }

        if (svgElem.hasAttribute('height')) {
            let heightAttr = svgElem.getAttribute('height');
            let heightAttrSplit = heightAttr.match(expr);

            svgHeight = Number(heightAttrSplit[1]);
            heightUnits = heightAttrSplit[2];
        } else {
            svgHeight = viewBox[3];
        }

        let jigData = {};
        let jigWidth = this.convertUnitsValueToMM(svgWidth, widthUnits);
        let jigHeight = this.convertUnitsValueToMM(svgHeight, heightUnits);

        jigData.width = this.toNDecimal(jigWidth);
        jigData.height = this.toNDecimal(jigHeight);
        jigData.thickness = 0.0;
        jigData.source = "SVG";

        // Read in the slot rects.
        let slots = [];
        let elemList = [],
            elemMap = {};

        let supportedShapes = ['rect', 'circle', 'polygon', 'ellipse', 'path'],
            hasUnsupportedShapes = false;

        let elems = svgDoc.getElementsByTagName('*'); // Get all elements
        for (let i = 0, length = elems.length; i < length; i++) {
            let elem = elems[i];
            if (elem.hasAttribute('data-skip')) {
                continue;
            }
            let elemName = elem.nodeName;
            if (elemName === 'line' || elemName === 'polyline') {
                hasUnsupportedShapes = true;
            } else if (supportedShapes.indexOf(elemName) !== -1) {
                if (!elem.hasAttribute('id')) {
                    // Skip over objects without ids.
                    continue;
                }

                // Object ids are of the form s1, p1, s2, p2 etc.
                // The s ids represent slots and the p ids represent print areas inside slots.
                let match = elem.getAttribute('id').match(/^([sp])(\d+).*$/i);
                if (match) {
                    // Bring the slot and its print area together.
                    // For id s1, match[0] = s1, match[1] = s and match[2] = 1
                    let id = match[2],
                        el = elemMap[id];
                    if (!el) {
                        el = {
                            id: id
                        };
                        elemMap[id] = el;
                        elemList.push(el);
                    }
                    if (match[1].toLowerCase() === 's') {
                        el.slot = elem;
                    } else {
                        el.print = elem;
                    }
                }
            }
        }

        let EPSILON = 1e-3;

        let scaleX = this.convertUnitsValueToMM(svgWidth / viewBox[2], widthUnits),
            scaleY = this.convertUnitsValueToMM(svgHeight / viewBox[3], heightUnits);

        elemList = elemList.filter(e => e.slot);
        for (let i = 0, length = elemList.length; i < length; i++) {
            let elem = elemList[i];

            let slot = this.parseSvgShape(elem.slot, scaleX, scaleY),
                print = elem.print ?
                    this.parseSvgShape(elem.print, scaleX, scaleY) :
                    JSON.parse(JSON.stringify(slot));

            if ((slot.type === 'path' && slot.hasOpenSubpaths) ||
                (print.type === 'path' && print.hasOpenSubpaths)) {
                // If path is open, we skip over it.
                hasUnsupportedShapes = true;
                continue;
            }

            // Check that the slot is contained within the jig bounds.
            if (slot.x < -EPSILON ||
                slot.y < -EPSILON ||
                (slot.x + slot.width) > (jigWidth + EPSILON) ||
                (slot.y + slot.height) > (jigHeight + EPSILON)) {
                throw new SvgParserException(SvgParserError.ERROR_SLOTS_EXCEED_JIG);
            }

            slot.order = elem.id;
            this.transformShape(slot, [-1, 0, 0, -1, jigWidth, jigHeight], 1, 1);
            slot.type = slot.type.toUpperCase();

            this.transformShape(print, [-1, 0, 0, -1, jigWidth - slot.x, jigHeight - slot.y], 1, 1);
            print.type = print.type.toUpperCase();

            // Check that the print area is contained within the slot bounds.
            if (print.x < -EPSILON ||
                print.y < -EPSILON ||
                (print.x + print.width) > (slot.width + EPSILON) ||
                (print.y + print.height) > (slot.height + EPSILON)) {
                throw new SvgParserException(SvgParserError.ERROR_PRINTS_EXCEED_SLOT);
            }

            // Set the default image box properties.
            print.rotation = 0;
            print.mirror = "NONE";
            print.scale = "TRUE";
            print.placement = "MIDDLE_CENTER";

            slot.imageBox = print;
            slots.push(slot);
        }

        slots.sort(function (a, b) {
            return a.order - b.order;
        });

        slots.forEach(function (slot) {
            delete slot.order;
        });

        jigData.slots = slots;
        jigData.hasUnsupportedShapes = hasUnsupportedShapes;

        return jigData;
    },

    transformShape: function (slot, matrix, slotOrigX, slotOrigY) {
        let m = matrix;
        if (slotOrigX || slotOrigY) {
            slotOrigX = slotOrigX || 0;
            slotOrigY = slotOrigY || 0;
            m = m.slice();
            m[4] += m[0] * slotOrigX * slot.width;
            m[5] += m[3] * slotOrigY * slot.height;
        }
        this.updaterCoordinates(slot, 'x', 'y', m);
        this.updaterCoordinates(slot, 'cx', 'cy', matrix);
        if (slot.path) {
            let relativeMatrix = matrix.slice();
            relativeMatrix[4] = 0;
            relativeMatrix[5] = 0;
            slot.path.forEach(arr => {
                let command = arr[0];
                let isAbsolute = command >= 'A' && command <= 'Z';
                for (let i = 1, len = arr.length; i < len; i += 2) {
                    if (command === 'V') {
                        this.updaterCoordinates(arr, null, i, matrix);
                    } else {
                        this.updaterCoordinates(arr, i, i + 1, isAbsolute ? matrix : relativeMatrix);
                    }
                }
            });
        }
    },

    updaterCoordinates: function (slot, x, y, [scaleX, skewY, skewX, scaleY, tx, ty]) {
        if (x && slot[x] !== undefined && slot[x] !== null)
            slot[x] = this.toNDecimal(tx + scaleX * slot[x]);
        if (y && slot[y] !== undefined && slot[y] !== null)
            slot[y] = this.toNDecimal(ty + scaleY * slot[y]);
    },

    toNDecimal: function (value, n = 3) {
        return Number(value.toFixed(n))
    },

    convertUnitsValueToMM: function (value, units) {
        switch (units) {
            case 'px':
            case 'pt':
            case '':
                return value / 72.0 * 25.4;

            case 'cm':
                return value * 10;

            case 'mm':
                return value;

            case 'in':
                return value * 25.4;

            default:
                break;
        }

        return value;
    },

    parseSvgShape: function (elem, scaleX, scaleY) {
        let shape = {},
            type = elem.nodeName,
            left, top, width, height;

        if (type === 'rect') {
            left = Number(elem.getAttribute('x'));
            top = Number(elem.getAttribute('y'));
            width = Number(elem.getAttribute('width'));
            height = Number(elem.getAttribute('height'));
            let transform = elem.getAttribute('transform');

            if (transform) {
                let matrix = fabUtils.parseTransformAttribute(transform);
                let points = [
                    { x: left, y: top },
                    { x: left + width, y: top },
                    { x: left + width, y: top + height },
                    { x: left, y: top + height },
                ];

                points.forEach(function (point) {
                    let x = matrix[0] * point.x + matrix[2] * point.y + matrix[4],
                        y = matrix[1] * point.x + matrix[3] * point.y + matrix[5];
                    point.x = x;
                    point.y = y;
                });

                let pathData = this.createPathFromPoints(points, scaleX, scaleY);

                shape.path = pathData.path;

                left = pathData.left;
                top = pathData.top;
                width = pathData.right - left;
                height = pathData.bottom - top;

                type = 'path';
            }
        } else if (type === 'circle') {
            let cx = Number(elem.getAttribute('cx')),
                cy = Number(elem.getAttribute('cy')),
                r = Number(elem.getAttribute('r'));

            shape.cx = cx * scaleX;
            shape.cy = cy * scaleY;
            shape.r = r * scaleX;

            left = cx - r;
            top = cy - r;
            width = 2 * r;
            height = 2 * r;
        } else if (type === 'ellipse') {
            let cx = Number(elem.getAttribute('cx')),
                cy = Number(elem.getAttribute('cy')),
                rx = Number(elem.getAttribute('rx')),
                ry = Number(elem.getAttribute('ry'));

            shape.cx = cx * scaleX;
            shape.cy = cy * scaleY;
            shape.rx = rx * scaleX;
            shape.ry = ry * scaleY;

            left = cx - rx;
            top = cy - ry;
            width = 2 * rx;
            height = 2 * ry;
        } else if (type === 'polygon') {
            let points = this.parsePointsAttribute(elem.getAttribute('points')),
                pathData = this.createPathFromPoints(points, scaleX, scaleY);

            shape.path = pathData.path;

            left = pathData.left;
            top = pathData.top;
            width = pathData.right - left;
            height = pathData.bottom - top;

            type = 'path';
        } else if (type === 'path') {
            let path = elem.getAttribute('d'),
                dim;

            path = fabUtils.parsePath(path);
            dim = fabUtils.parseDimensions(path);

            let subpathClosed = true,
                hasOpenSubpaths = false;

            for (let i = 0, numCommand = path.length; i < numCommand; i++) {
                let currentPath = path[i];

                // Check if any of the subpaths is open.
                if (!hasOpenSubpaths) {
                    if (currentPath[0] === 'm' || currentPath[0] === 'M') {
                        // Starts a sub path.
                        if (!subpathClosed) {
                            // Previous sub path is not closed.
                            hasOpenSubpaths = true;
                        }
                        subpathClosed = false;
                    } else if (currentPath[0] === 'z' || currentPath[0] === 'Z') {
                        // Closes a sub path.
                        subpathClosed = true;
                    }
                }

                if (currentPath[0] !== 'v' && currentPath[0] !== 'V') {
                    for (let j = 1, length = currentPath.length; j < length; j++) {
                        currentPath[j] *= (j % 2 === 0) ? scaleY : scaleX;
                    }
                } else {
                    currentPath[1] *= scaleY;
                }
            }

            if (!subpathClosed) {
                hasOpenSubpaths = true;
            }

            shape.path = path;
            shape.hasOpenSubpaths = hasOpenSubpaths;

            left = dim.left;
            top = dim.top;
            width = dim.width;
            height = dim.height;
        }

        shape.x = this.toNDecimal(left * scaleX);
        shape.y = this.toNDecimal(top * scaleY);
        shape.width = this.toNDecimal(width * scaleX);
        shape.height = this.toNDecimal(height * scaleY);
        shape.type = type;

        return shape;
    },

    createPathFromPoints: function (points, scaleX, scaleY) {
        let min = fabUtils.array.min,
            max = fabUtils.array.max;

        let path = [],
            aX = [],
            aY = [];

        for (let i = 0, length = points.length; i < length; i++) {
            let px = points[i].x,
                py = points[i].y;

            if (i === 0) {
                path.push(['M', this.toNDecimal(px * scaleX), this.toNDecimal(py * scaleY)]);
            } else {
                path.push(['L', this.toNDecimal(px * scaleX), this.toNDecimal(py * scaleY)]);
            }

            aX.push(this.toNDecimal(px));
            aY.push(this.toNDecimal(py));
        }
        path.push(['Z']); // close the path

        return {
            path: path,
            left: min(aX) || 0,
            top: min(aY) || 0,
            right: max(aX) || 0,
            bottom: max(aY) || 0
        };
    },
    parsePointsAttribute: function (points) {
        if (!points) {
            return null;
        }
        points = points.replace(/,/g, ' ').trim();
        points = points.split(/\s+/);
        let parsedPoints = [], i, len;

        for (i = 0, len = points.length; i < len; i += 2) {
            parsedPoints.push({
                x: parseFloat(points[i]),
                y: parseFloat(points[i + 1])
            });
        }
        return parsedPoints;
    }
}

const svgEncoder = {
    encodeJigToSvg: function (jig) {
        let markup = [],
            subMarkup = [];
        this.writeSvgHeader(jig.name, jig.width, jig.height, markup);
        this.writeSvgStyle(subMarkup);
        subMarkup.push(`<rect x="0" y="0" class="st1" width="${jig.width}" height="${jig.height}" data-skip="true" />`);
        this.encodeJigSlots(jig, subMarkup);
        markup.push(subMarkup);
        markup.push('</svg>');
        return this.getSvgMarkup(markup);
    },

    encodeTableToSvg: function (table) {
        let markup = [],
            subMarkup = [];
        this.writeSvgHeader(table.name, table.width, table.height, markup);
        this.writeSvgStyle(subMarkup);
        subMarkup.push(`<rect x="0" y="0" class="st0" width="${table.width}" height="${table.height}"/>`);
        (table.jigs || []).forEach((jig, index) => {
            subMarkup.push(`<g transform="translate(${table.width - jig.x - jig.width}, ${table.height - jig.y - jig.height})">`);
            let slotMarkup = [];
            slotMarkup.push(`<rect x="0" y="0" class="st1" width="${jig.width}" height="${jig.height}"/>`);
            slotMarkup.push(`<text x="5" y="5" alignment-baseline="text-before-edge">Jig-${index + 1}</text>`);
            this.encodeJigSlots(jig, slotMarkup);
            subMarkup.push(slotMarkup);
            subMarkup.push('</g>');
        });
        markup.push(subMarkup);
        markup.push('</svg>');
        return this.getSvgMarkup(markup);
    },

    encodeJigSlots: function (jig, markup) {
        let width = jig.width,
            height = jig.height;
        (jig.slots || []).forEach((slot, index) => {
            slot = JSON.parse(JSON.stringify(slot));
            let imageBox = slot.imageBox;
            svgParser.transformShape(imageBox, [-1, 0, 0, -1, width - slot.x, height - slot.y], 1, 1);
            svgParser.transformShape(slot, [-1, 0, 0, -1, width, height], 1, 1);
            this.encodeSlot(slot, markup, index + 1);
        });
    },

    writeSvgHeader: function (id, width, height, markup) {
        markup.push(
            '<?xml version="1.0" encoding="utf-8"?>'
        );
        markup.push(
            `<svg version="1.1" id="${id || 'layer1'}" xmlns="http://www.w3.org/2000/svg" ` +
            'xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" xml:space="preserve" ' +
            `viewBox="0 0 ${width} ${height}" style="enable-background:new 0 0 ${width} ${height};" width="${width}mm" height="${height}mm">`
        );
    },

    writeSvgStyle: function (markup) {
        markup.push(
            '<style type="text/css">',
            '.st0{fill:#EEE; stroke:#707070;}',
            '.st1{fill:#BFBFBF; stroke:#707070;}',
            '.st2{fill:#C2B5A4; stroke:#707070;}',
            '.st3{fill:#D8BFD8; stroke:#707070;}',
            'text{font-family: Helvetica Neue, Arial, sans-serif; fill:#000; font-size:0.4rem; font-weight:bold;}',
            '</style>'
        );
    },

    encodeSlot: function (slot, markup, index) {
        let subMarkup = [];
        markup.push(`<g>`);
        this.encodeShape(slot, 's' + index, 'st2', subMarkup);
        this.encodeShape(slot.imageBox, 'p' + index, 'st3', subMarkup);
        subMarkup.push(
            `<text x="${slot.x + slot.width / 2}" y="${slot.y + slot.height / 2}" alignment-baseline="middle" text-anchor="middle">` +
            `${index}</text>`
        );
        markup.push(subMarkup);
        markup.push('</g>');
    },

    encodeShape: function (slot, id, cls, markup) {
        let slotType = slot.type || "RECT";
        switch (slotType) {
            case 'CIRCLE':
                markup.push(`<circle id="${id}" cx="${slot.cx}" cy="${slot.cy}" class="${cls}" r="${slot.r}"/>`);
                break;
            case 'ELLIPSE':
                markup.push(`<ellipse id="${id}" cx="${slot.cx}" cy="${slot.cy}" class="${cls}" rx="${slot.rx}" ry="${slot.ry}"/>`);
                break;
            case 'PATH':
                let d = slot.path;
                d = d.map(i => i.join(' '));
                d = d.join(' ');
                markup.push(`<path id="${id}" class="${cls}" d="${d}"/>`);
                break;
            default:
                markup.push(`<rect id="${id}" x="${slot.x}" y="${slot.y}" class="${cls}" width="${slot.width}" height="${slot.height}"/>`);
                break;
        }
    },

    getSvgMarkup: function (markup, level) {
        let str = '';
        level = level || 0;
        markup.forEach((item) => {
            if (item instanceof Array) {
                str += this.getSvgMarkup(item, level + 1);
            } else {
                for (let i = 0; i < level; ++i) {
                    str += '\t';
                }
                str += item + '\n';
            }
        });
        return str;
    }
}

export function encodeJigToSvg(jig) {
    return svgEncoder.encodeJigToSvg(jig);
};

export function encodeTableToSvg(table) {
    return svgEncoder.encodeTableToSvg(table);
};

export function parseJigSvg(svg) {
    return svgParser.parseJigSvg(svgParser.getSvgDocument(svg));
};

export function getSvgDocument(svg) {
    return svgParser.getSvgDocument(svg);
};