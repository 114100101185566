import { createSlice } from "@reduxjs/toolkit";
import Utils from "../../Utils";

const initialState = {
  allUsers: { users: [], selectedIndex: 0, currentUser: {} },
  userStorageFull: false
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    fetchUsers: (state, action) => {
      let users = [...action.payload.users];

      if (users && users.length > 0) {
        for (let ind = 0; ind < users.length; ind++) {
          if (!users[ind].permissions) {
            users[ind] = {
              ...users[ind],
              permissions: Utils.getDefaultPermissions(users[ind]).toString()
            }
          }
        }
      }

      state.allUsers.users = [...users];
    },
    createUser: (state, action) => {
      let userData = { ...action.payload.user };
      if (userData.userId) {
        userData = {
          ...action.payload.user,
          userId: userData.userId.toLowerCase()
        }
      }
      state.allUsers.users.push(userData);
      state.allUsers.selectedIndex = state.allUsers.users.length - 1;
    },
    updateUser: (state, action) => {
      const index = state.allUsers.users.findIndex(
        (item) => item.id === action.payload.user.id
      );
      state.allUsers.users.splice(index, 1, action.payload.user);
      state.allUsers.selectedIndex = index;

      if (state.allUsers.currentUser.id && state.allUsers.currentUser.id === action.payload.user.id) {
        state.allUsers.currentUser = {
          ...state.allUsers.currentUser,
          ...action.payload.user
        }
      }
    },
    deleteUser: (state, action) => {
      const index = state.allUsers.users.findIndex(
        (item) => item.id === action.payload.id
      );
      state.allUsers.users.splice(index, 1);
      if (state.allUsers.users.length) {
        state.allUsers.selectedIndex = 0;
      }
      else {
        state.allUsers.selectedIndex = -1;
      }
    },
    fetchProfile: (state, action) => {
      let userData = { ...action.payload.user };
      if (Utils.isSuperAdminOrB2bRemoteOrB2cRemoteUser(userData)) {
        userData = {
          ...userData,
          permissions: Utils.getDefaultAdminPermissions().toString()
        }
      } else if (userData.role && !userData.permissions) {
        userData = {
          ...userData,
          permissions: Utils.getDefaultPermissions(userData).toString()
        }
      }
      state.allUsers.currentUser = { ...userData }
    },
    updateUserStorageFull: (state, action) => {
      state.userStorageFull = action.payload;
    }
  },
});

export const usersActions = usersSlice.actions;

export default usersSlice.reducer;
