import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import Utils from "../../Utils";

function UnitInput(props) {
  const unit = useSelector((state) => state.config.config.unit, shallowEqual);
  const [value, setValue] = useState(Utils.formatMMToUnits(props.value, unit));
  const step = unit === "mm" ? 1 : 0.1;

  useEffect(() => {
    if (props.changedByCode) {
      const newValue = Utils.formatMMToUnits(props.value, unit);
      if (Number(value) !== Number(newValue)) {
        setValue(newValue);
      }
    }
  }, [props.value]);

  return (
    <input
      type="number"
      name={props.name}
      onChange={(e) => {
        // Report mm value to external world.
        if (!e.target.value) {
          props.onChange(0);
          setValue(e.target.value);
        } else {
          let value = parseFloat(e.target.value);
          if (props.maxValue) {
            if (value > props.maxValue) {
              value = props.maxValue;
            }
          }
          props.onChange(Utils.convertUnitsToMM(value, unit));
          setValue(value);
        }
      }}
      min={props.min || 0}
      value={value}
      step={props.step || step}
      onKeyDown={(e) => Utils.inputPrevent(e, props.allowNegativeValue)}
      disabled={props.disabled}
    />
  );
}

export default UnitInput;
