import axios from 'axios';

axios.interceptors.request.use(function (request) {
    window.loading(true);
    if (window.getResourceName()) {
        request.headers['Customer'] = window.getResourceName();
    }
    return request;
});


axios.interceptors.response.use(function (response) {
    window.loading(false);
    const errorCode = response.data.errorCode;
    if (errorCode == "invalid_customer" && window.setInvalidDomain) {
        window.setInvalidDomain(true);
    }
    return response;
}, function (error) {
    window.loading(false);
    if (error?.response?.status === 401) {
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("isRdgUser");
        window.location = "/" + (window.getResourceName() || '');
    }
    return Promise.reject(error);
});

export default axios;