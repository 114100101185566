import React, { useRef, useEffect, useState } from 'react'
import classes from "./StorageCard.module.css"
import { useDispatch, useSelector } from 'react-redux'
import { usersActions } from '../../modules/Users/UsersSlice'
import { getUserStorageQuota } from '../../modules/Orders/StorageActions'
import Utils from '../../Utils'

const StorageCard = () => {
    const [storageDetails, setStorageDetails] = useState({ percentStorageUsed: 0, totalStorage: 0, storageUsed: 0 });
    const [error, setError] = useState(false);
    const userStorageFull = useSelector(state => state.users.userStorageFull)
    const dispatch = useDispatch();
    useEffect(() => {
        if (localStorage.getItem("isRdgUser") && storageDetails.percentStorageUsed >= 100) {
            setError(true);
            dispatch(usersActions.updateUserStorageFull(true));
        } else if (userStorageFull) {
            setError(false);
            if (storageDetails.percentStorageUsed < 100) dispatch(usersActions.updateUserStorageFull(false));
        }
    }, [storageDetails])

    useEffect(async () => {
        if (localStorage.getItem("isRdgUser")) {
            const data = await getUserStorageQuota(dispatch);
            setStorageDetails((prevState) => {
                return {
                    ...prevState,
                    percentStorageUsed: data.percentStorageUsed,
                    totalStorage: data.totalStorage,
                    storageUsed: data.storageUsed
                }
            })
        }
    }, [])


    return (
        <>
            {error && Utils.showErrorDialog("Please purchase extra storage or delete data to continue", () => {setError(false) })}
            <div className={classes.main} >
                <div className={classes.pieContainer} >
                    <Pie percentage={storageDetails.percentStorageUsed} colour={"#0273D1"} size={60} />
                </div>
                <div className={classes.storageDetailBox}>
                    <div style={{ marginBottom: "10px" }}>Total Storage : {storageDetails.totalStorage}</div>
                    <div>Storage Used : {storageDetails.storageUsed}</div>
                </div>
            </div>
        </>
    )
}

const cleanPercentage = (percentage) => {
    const tooLow = !Number.isFinite(+percentage) || percentage < 0;
    const tooHigh = percentage > 100;
    return tooLow ? 0 : tooHigh ? 100 : +percentage;
};

const Circle = ({ colour, pct, radius, strokeWidth, size }) => {
    const circ = 2 * Math.PI * radius;
    const strokePct = ((100 - pct) * circ) / 100;
    return (
        <circle
            r={radius}
            cx={size / 2}
            cy={size / 2}
            fill="transparent"
            stroke={strokePct !== circ ? colour : ""}
            strokeWidth={strokeWidth}
            strokeDasharray={circ}
            strokeDashoffset={pct ? strokePct : 0}
            strokeLinecap="round"
        ></circle>
    );
};

const Text = ({ percentage }) => {
    return (
        <text
            x="50%"
            y="50%"
            dominantBaseline="central"
            textAnchor="middle"
            fontSize={"0.775rem"}
            fill='black'
        >
            {percentage.toFixed(0)}%
        </text>
    );
};

export const Pie = ({ percentage, colour, size }) => {
    const pct = cleanPercentage(percentage);
    const strokeWidth = 0.15 * size
    const radius = (size / 2) - (strokeWidth / 2);

    return (
        <svg width={size} height={size}>
            <g transform={`rotate(-90 ${size / 2} ${size / 2})`}>
                <Circle colour="lightgrey" radius={radius} size={size} strokeWidth={strokeWidth} />
                <Circle colour={colour} pct={pct} radius={radius} strokeWidth={strokeWidth} size={size} />
            </g>
            <Text percentage={pct} />
        </svg>
    );
};

export default StorageCard