import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import OrderViewer from './OrderViewer';
import classes from './Orders.module.css';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import EditOrder from './EditOrder';
import SliderButton from '../../../components/Buttons/SliderButton';
import { activateOrder, deleteOrder, getAllOrders, reprocessOrders } from '../OrdersActions';
import Utils from '../../../Utils';
import { updateOutputPreset } from '../OutputPresetActions';
import OrderUtils from './OrderUtils';
import OrderTable from './OrderTable';
import Confirm from '../../../components/Confirm/Confirm';
import { useIdMap } from '../../../custom-hooks/IdMap';
import PrintPanel from './PrintPanel';
import StorageCard from '../../../components/StorageCard/StorageCard';
import { getUpdatedUserStorage, updateUserStorageQuota } from '../StorageActions';

function checkOrderProp(a, b) {
	if (a === undefined || b === undefined) {
		return true;
	}
	return a === b;
}

function orderMapper(o, masterData) {
	const val = { ...o };
	Object.keys(masterData).forEach(key => {
		if (typeof masterData[key] === 'object' && val[key]) {
			val[key] = masterData[key][val[key]];
		}
	})
	return val;
}

function Orders() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const allMasters = useSelector((state) => state.master.allMasters, shallowEqual) || {};
	const allOrders = useSelector((state) => state.orders.allOrders, shallowEqual);
	const historyOrders = useSelector((state) => state.orders.historyOrders, shallowEqual);
	const allJigs = useSelector((state) => state.jigs.allJigs.jigs, shallowEqual);
	const allUserTables = useSelector((state) => state.tables.userTables.tables, shallowEqual);
	const allTables = useSelector((state) => state.tables.allTables.tables, shallowEqual);
	const allUserOutputChannels = useSelector((state) => state.outputChannels.allOutputChannels, shallowEqual);
	const allUserOutputPresets = useSelector((state) => state.outputPresets.allOutputPresets, shallowEqual);
	const initialConfig = useSelector(((state) => state.config.config), shallowEqual) || {};
	const currentUser = useSelector((state) => state.users.allUsers.currentUser) || {};
	const permissions = useSelector((state) => state.users.allUsers.currentUser.permissions || "");
	const [activeColumns, setActiveColumns] = useState(OrderUtils.getColumns(permissions, true, deleteOrderHandler, editOrderHandler));
	const [showEditOrder, setShowEditOrder] = useState(false);
	const [selectedOrder, setSelectedOrder] = useState({});
	const [showError, setShowError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [orderTableActiveIndex, setOrderTableActiveIndex] = useState(-1);
	const [showHistoryOrders, setShowHistoryOrders] = useState(false);
	const [historyColumns, setHistoryColumns] = useState(OrderUtils.getColumns(permissions, false, deleteOrderHandler));
	const [selectedHistoryOrder, setSelectedHistoryOrder] = useState({});
	const [historyTableActiveIndex, setHistoryTableActiveIndex] = useState(-1);
	const [showHistoryEditOrder, setShowHistoryEditOrder] = useState(false);
	const [allOrdersLength, setAllOrdersLength] = useState(allOrders.length);
	const [showReprocessConfirmation, setShowReprocessConfirmation] = useState(false);
	const [showDeleteOrderConfirmation, setShowDeleteOrderConfirmation] = useState(false);
	const [showRemoveOrdersConfirmation, setShowRemoveOrdersConfirmation] = useState(false);
	const [userTables, setUserTables] = useState(allUserTables || []);
	const [outputPresetIndex, setOutputPresetIndex] = useState(0);
	const [outputPreset, setOutputPreset] = useState();
	const [key, setKey] = useState(false);


	const [orderFilterValue, setOrderFilterValue] = useState(window.orderFilterValue || "ALL_ORDERS");
	useEffect(() => {
		window.orderFilterValue = orderFilterValue;
	}, [orderFilterValue]);

	useEffect(() => {
		setActiveColumns(OrderUtils.getColumns(permissions, true, deleteOrderHandler, editOrderHandler));
		setHistoryColumns(OrderUtils.getColumns(permissions, false, deleteOrderHandler));
	}, [permissions]);

	const [allOutputChannels, setAllOutputChannels] = useState([]);
	useEffect(() => {
		if (currentUser && allUserOutputChannels?.length) {
			setAllOutputChannels(allUserOutputChannels.filter((outputChannel) => outputChannel.userId === currentUser.id));
		}
	}, [allUserOutputChannels, currentUser]);

	const [allOutputPresets, setAllOutputPresets] = useState([]);
	useEffect(() => {
		if (currentUser && allUserOutputPresets?.length) {
			setAllOutputPresets(allUserOutputPresets.filter((outputPreset) => outputPreset.userId === currentUser.id));
		}
	}, [allUserOutputPresets, currentUser]);


	useEffect(() => {
		if (allOutputPresets?.length) {
			setOutputPreset(allOutputPresets[outputPresetIndex]);
		}
	}, [allOutputPresets, outputPresetIndex])


	// allUserTables gives us the list of tables this user can be assigned jobs for,
	// on the basis of his user rules set.
	// However, we also need to add tables for which the user currently has orders
	// assigned for.
	const totalTables = new Map();
	const userTableIds = new Set();
	userTables.forEach(table => {
		totalTables.set(table.id, Utils.expandTableDataWithJigDetails(table, allJigs));
		userTableIds.add(table.id);
	});

	const allTablesMap = new Map();
	allTables.forEach((table) => {
		allTablesMap.set(table.id, table);
	});

	const [jigNamesMap] = useIdMap(allJigs);
	const totalJigs = new Map();
	if (jigNamesMap) {
		allTables.forEach(table => {
			const jigs = table.jigs;
			jigs.forEach((jig) => {
				let jigName = jigNamesMap[jig.id]?.name;
				let jigTables = totalJigs.get(jigName);
				if (jigTables) {
					jigTables.push(table);
				} else {
					totalJigs.set(jigName, [table]);
				}
			});
		});
	}

	const totalOutputChannels = new Map();
	let isSingleHost = OrderUtils.checkSingleHost(allOutputChannels);
	allOutputChannels.forEach(outputChannel => {
		let obj = {
			...outputChannel,
			name: OrderUtils.getChannelName(outputChannel, isSingleHost)
		};
		totalOutputChannels.set(outputChannel.id, obj);
	});

	const [tableData, setTableData] = useState(OrderUtils.getIntialTableData(allOutputPresets, totalTables));

	useEffect(() => {
		// Update the user tables with additional tables for assigned orders.

		// Collect order jigs.
		const uniqueJigs = new Set();
		allOrders.forEach((order) => {
			if (order.jig) {
				uniqueJigs.add(order.jig);
			}
		});

		// For each order jig, check if there are tables which are not already present
		// in user's tables list.
		let tableData = [...userTables];
		for (let jig of uniqueJigs.values()) {
			const jigTables = totalJigs.get(jig);
			if (jigTables && jigTables.length > 0) {
				jigTables.forEach((table) => {
					if (!userTableIds.has(table.id)) {
						// Add if not already present.
						totalTables.set(table.id, Utils.expandTableDataWithJigDetails(table, allJigs));
						tableData.push(table);
						userTableIds.add(table.id);
					}
				});
			}
		}
		// Add table which is present in the outputPreset and allTables list but not in the user tables or filter. 
		allOutputPresets.forEach((outputPreset) => {
			const table = allTablesMap.get(outputPreset.tableId);
			if (outputPreset.tableId && table && !userTableIds.has(outputPreset.tableId)) {
				totalTables.set(outputPreset.tableId, Utils.expandTableDataWithJigDetails(table, allJigs));
				tableData.push(table);
				userTableIds.add(table.id);
			}
		});
		setUserTables(tableData);
	}, [allOrders, allJigs, allUserTables, allTables, allOutputPresets]);

	useEffect(() => {
		setTableData(OrderUtils.getIntialTableData(allOutputPresets, totalTables));
	}, [allUserOutputPresets, userTables, allJigs]);

	useEffect(() => {
		if (orderTableActiveIndex >= 0 && allOrders.length > orderTableActiveIndex) {
			setSelectedOrder(allOrders[orderTableActiveIndex]);
		}
		setAllOrdersLength(allOrders.length);
	}, [allOrders]);

	useEffect(() => {
		setSelectedOrder({});
		setOrderTableActiveIndex(-1);
	}, [allOrdersLength]);

	useEffect(() => {
		if (historyTableActiveIndex >= 0) {
			setSelectedHistoryOrder({});
			setHistoryTableActiveIndex(-1);
		}
	}, [historyOrders])

	useEffect(() => {
		if (initialConfig && initialConfig.autoPollingIntervalMins) {
			const intervalId = setInterval(() => getAllOrders(dispatch), 1000 * 60 * initialConfig.autoPollingIntervalMins);
			return () => clearInterval(intervalId);
		}
	}, [initialConfig]);

	function editOrderHandler() {
		setShowEditOrder(true);
	}

	function updateHandler(outputPreset, removeOrder) {
		if (outputPresetIndex >= 0) {
			updateOutputPreset(dispatch, { presetData: [outputPreset] }).then((response) => {
				if (response && response.data && response.data.success) {
					setOutputPreset(() => {
						const obj = { ...outputPreset }
						delete obj.tableData
						return obj;
					});
					setShowError(false);
				}
				else {
					if (removeOrder) {
						setErrorMessage(t('orders.error.removeOrdersFailed'));
					} else {
						setErrorMessage(t('orders.error.updatePresetFailed'));
					}
					setShowError(true);
				}
			});
		}
	}

	function getFullPreset(i) {
		if (!i) {
			return i;
		}
		let outputChannel = totalOutputChannels.get(i.outputChannelId);
		return {
			...i,
			_params: {
				...i.filterParms,
				printerType: outputChannel?.printerType
			}
		};
	}

	function autoFillOrderHandler() {
		const [assignedData, orderData] = Utils.assignOrdersToAllPresets(allOrders, tableData, allOutputPresets.map(getFullPreset));
		const tableWithOrders = [];
		let isOrderAssigned = false;
		for (let i = 0; i < assignedData.length; i++) {
			if (assignedData[i] && assignedData[i].isOrderAssigned && orderData.length) {
				let obj = {
					...allOutputPresets[i],
					tableData: assignedData[i]
				}
				isOrderAssigned = true;
				tableWithOrders.push(obj);
			}
		}
		if (isOrderAssigned) {
			updateOutputPreset(dispatch, { presetData: tableWithOrders, orderData: orderData }).then((response) => {
				if (response && response.data && response.data.success) {
					setTableData(assignedData);
					setOutputPreset((prevState) => {
						return {
							...prevState,
							tableData: assignedData[outputPresetIndex],
						}
					}
					);
				}
			});
		}
	}

	function addOrderHandler() {
		const [assignedOrderData, orderData] = Utils.assignOrdersToPreset(
			selectedOrder, tableData[outputPresetIndex],
			getFullPreset(allOutputPresets[outputPresetIndex]),
			false
		);
		if (assignedOrderData && assignedOrderData.isOrderAssigned) {
			let presetArr = [{ ...allOutputPresets[outputPresetIndex], tableData: assignedOrderData }];
			updateOutputPreset(dispatch, {
				presetData: presetArr, orderData: orderData
			}).then((response) => {
				if (response && response.data && response.data.success) {
					setTableData((prevState) => {
						let temp = [...prevState];
						let obj = {
							...temp[outputPresetIndex],
							tableData: assignedOrderData,
							orderData: orderData
						}
						temp[outputPresetIndex] = obj;
						return temp;
					});
					setOutputPreset((prevState) => {
						return {
							...prevState,
							tableData: assignedOrderData,
						}
					});
				}
			});
		}
	}

	function addOneOrderHandler() {
		const [assignedOrderData, orderData] = Utils.assignOrdersToPreset(
			selectedOrder, tableData[outputPresetIndex],
			getFullPreset(allOutputPresets[outputPresetIndex]),
			true
		);
		if (assignedOrderData && assignedOrderData.isOrderAssigned) {
			let presetArr = [{ ...allOutputPresets[outputPresetIndex], tableData: assignedOrderData }];
			updateOutputPreset(dispatch, { presetData: presetArr, orderData: orderData }).then((response) => {
				if (response && response.data && response.data.success) {
					setTableData((prevState) => {
						let temp = [...prevState];
						let obj = {
							...temp[outputPresetIndex],
							tableData: assignedOrderData,
							orderData: orderData
						}
						temp[outputPresetIndex] = obj;
						return temp;
					});
					setOutputPreset((prevState) => {
						return {
							...prevState,
							tableData: assignedOrderData
						}
					});
				}
			});
		}
	}

	function isAddEnabled() {
		// Allow add if:
		// We have an active output preset with valid table and output channel, and
		// active preset is not full, and
		// an active order/job with a valid jig assignment, and
		// at least one quantity of the job's active frame can be assigned to the table.
		if (outputPresetIndex >= 0 &&
			outputPreset && outputPreset.tableId &&
			((outputPreset.outputChannelId && totalOutputChannels.get(outputPreset.outputChannelId)) || outputPreset.multiJobName) &&
			totalTables.get(outputPreset.tableId) &&
			Object.keys(selectedOrder).length &&
			Utils.checkAvailableSlotForOrder(selectedOrder, tableData[outputPresetIndex])) {
			return true;
		}
		return false;
	}

	function isRemoveEnabled() {
		if (outputPresetIndex >= 0 &&
			outputPreset && outputPreset.tableData)
			return true;

		return false;
	}



	function filterOrders(order) {
		function matchFilter(table, preset) {
			let filterParms = getFullPreset(preset)._params;
			if (!Object.keys(filterParms).every(e => checkOrderProp(filterParms[e], order[e]))) {
				return false;
			}
			return table?.jigs.some(e => checkOrderProp(order.jig, e.name));
		}
		switch (orderFilterValue) {
			case "ACTIVE_PRESET":
				if (outputPresetIndex === -1) {
					return false;
				} else {
					if (tableData[outputPresetIndex]) {
						return matchFilter(tableData[outputPresetIndex], allOutputPresets[outputPresetIndex]);
					}
					return false;
				}
			case "ALL_PRESETS":
				return tableData.some((table, idx) => matchFilter(table, allOutputPresets[idx]));
			case "NO_PRESET":
				return !(tableData.some((table, idx) => matchFilter(table, allOutputPresets[idx])));
			case "UNASSIGNED":
				return !(order.users && order.users.length > 0);
			default:
				return true;
		}
	}

	function setActiveHandler() {
		activateOrder(dispatch, selectedHistoryOrder).then((response) => {
			console.log(response);
		});
	}

	function reprocessOrdersHandler() {
		setShowReprocessConfirmation(true);
	}

	function reprocessOrdersCloseHandler() {
		setShowReprocessConfirmation(false);
	}

	function confirmReprocessOrdersHandler() {
		reprocessOrders().then((response) => {
			if (response) {
				getAllOrders(dispatch);
				setShowReprocessConfirmation(false);
			} else {
				setShowReprocessConfirmation(false);
				setErrorMessage(t('orders.error.reprocessFailed'));
				setShowError(true);
			}
		});
	}

	function deleteOrderHandler() {
		setShowDeleteOrderConfirmation(true);
	}

	function confirmDeleteOrderHandler() {
		let data = {}
		if (!showHistoryOrders) {
			if (selectedOrder.assigned && allOutputPresets.length && allOutputPresets.some(i => i.status.toLowerCase() !== "empty")) {
				setShowDeleteOrderConfirmation(false);
				setErrorMessage(t('orders.error.deleteRefused'));
				setShowError(true);
				return
			}
			data.id = selectedOrder.objId;
			data.jobId = selectedOrder.jobId;
		} else {
			data.id = selectedHistoryOrder.objId;
			data.jobId = selectedHistoryOrder.jobId;
		}
		deleteOrder(dispatch, data).then(async (response) => {
			if (response && response.data && response.data.success) {
				setShowDeleteOrderConfirmation(false);
				try{					
					await updateUserStorageQuota(data);
					setKey(!key);
				}catch{}
			} else {
				setShowDeleteOrderConfirmation(false);
				setErrorMessage(t('orders.error.deleteFailed'));
				setShowError(true);
			}
		})
	}

	return (<>
		{showEditOrder && <EditOrder editOrderCloseHandler={() => setShowEditOrder(false)} editOrderSaveHandler={() => setShowEditOrder(false)} selectedOrder={selectedOrder} />}
		{showHistoryEditOrder && <EditOrder editOrderCloseHandler={() => setShowHistoryEditOrder(false)} editOrderSaveHandler={() => setShowHistoryOrders(false)} selectedOrder={selectedHistoryOrder} />}
		{showError && Utils.showErrorDialog(errorMessage, e => setShowError(false))}
		{showReprocessConfirmation &&
			<Confirm title={t('orders.reprocess.title')} onClose={reprocessOrdersCloseHandler} onConfirm={confirmReprocessOrdersHandler}
				message={t('orders.reprocess.message')} confirmLabel={t('orders.reprocess.title')} cancelLabel={t('button.cancel')} />
		}
		{showDeleteOrderConfirmation &&
			<Confirm title={t('button.delete')}
				onClose={e => {
					setShowDeleteOrderConfirmation(false);
				}}
				onConfirm={confirmDeleteOrderHandler}
				message={t('orders.delete.message')} confirmLabel={t('button.delete')} cancelLabel={t('button.cancel')} />
		}
		{showRemoveOrdersConfirmation &&
			<Confirm title={t('button.remove')}
				onClose={e => setShowRemoveOrdersConfirmation(false)}
				onConfirm={e => {
					setShowRemoveOrdersConfirmation(false);
					updateHandler({
						...outputPreset,
						status: "EMPTY"
					}, true);
				}}
				message={t('orders.removeOrders.message')} confirmLabel={t('button.remove')}
				cancelLabel={t('button.cancel')}
			/>
		}
		<div className={classes.main}>
			<div className={!Utils.canPrintOrder(permissions) ? classes.leftContainerAdmin : classes.leftContainer}>
				<div className={classes.container}>
					<h2> {t('orders.heading')} </h2>
					<div className={classes.headerSelect}>
						{!showHistoryOrders && !Utils.canPrintOrder(permissions) && <PrimaryButton label={t('orders.button.reprocess')} onClick={reprocessOrdersHandler} />}
						{showHistoryOrders ? <PrimaryButton label={t('button.setActive')} onClick={setActiveHandler} disabled={historyTableActiveIndex < 0} /> :
							<select value={orderFilterValue} onChange={(e) => setOrderFilterValue(e.target.value)}>
								<option value="ALL_ORDERS" >{t('orders.select.viewAllOrders')} </option>
								{!Utils.canPrintOrder(permissions) ?
									<option value='UNASSIGNED'>{t('orders.select.unassignedOrders')} </option>
									: <>
										<option value="ACTIVE_PRESET">{t('orders.select.activeOutputPreset')} </option>
										<option value="ALL_PRESETS">{t('orders.select.allOutputPresets')} </option>
										<option value='NO_PRESET'>{t('orders.select.noOutputPreset')} </option>
									</>
								}
							</select>
						}
					</div>
				</div>
				<div className={classes.gridTable}>
					{showHistoryOrders ? <OrderTable columns={historyColumns} rows={historyOrders.map(o => orderMapper(o, allMasters))} setColumns={setHistoryColumns} setOrder={setSelectedHistoryOrder} setTableActiveIndex={setHistoryTableActiveIndex} isActive={false} /> :
						<OrderTable columns={activeColumns} rows={allOrders.filter(filterOrders).map(o => orderMapper(o, allMasters))} setColumns={setActiveColumns} setOrder={setSelectedOrder} setTableActiveIndex={setOrderTableActiveIndex} isActive={true} />
					}
				</div>
				<div className={classes.orderViewer}>
					<div className={classes.storageCard}><StorageCard key={key} /></div>
					{showHistoryOrders && selectedHistoryOrder.thumbnailPath && <OrderViewer job={selectedHistoryOrder} />}
					{!showHistoryOrders && selectedOrder && selectedOrder.thumbnailPath && <OrderViewer job={selectedOrder} />}
				</div>
			</div>

			{!showHistoryOrders && Utils.canPrintOrder(permissions) &&
				<div className={classes.middleContainer}>
					<PrimaryButton onClick={autoFillOrderHandler} label={t('button.autoFill')} />
					<PrimaryButton onClick={addOrderHandler} label={t('button.addOrder')} disabled={!isAddEnabled()} />
					<PrimaryButton onClick={addOneOrderHandler} label={t('button.addOne')} disabled={!isAddEnabled()} />
					<PrimaryButton onClick={() => setShowRemoveOrdersConfirmation(true)} label={t('button.removeOrders')} disabled={!isRemoveEnabled()} />
				</div>
			}
			<div className={!Utils.canPrintOrder(permissions) ? classes.rightContainerAdmin : classes.rightContainer}>
				<div className={classes.activeBtn}>
					<SliderButton onChange={setShowHistoryOrders} checkedLabel={t('orders.label.orderHistory')} uncheckedLabel={t('orders.label.active')} />
				</div>
				{!showHistoryOrders && Utils.canPrintOrder(permissions) &&
					<PrintPanel outputPresets={allOutputPresets}
						selectedUser={currentUser}
						allOutputChannels={allOutputChannels}
						selectionIndex={outputPresetIndex}
						onSelectChange={idx => {
							setOutputPreset(allOutputPresets[idx]);
							setOutputPresetIndex(idx);
						}}
						onPresetUpdate={updateHandler}
						onError={e => {
							setErrorMessage(e.msg);
							setShowError(true);
						}}
					/>
				}
			</div>
		</div>
	</>);
}

export default Orders;
